<template>
  <v-card id="account-setting-card">
    <!-- tabs -->
    <v-tabs
      v-model="tab"
      show-arrows
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.icon"
      >
        <v-icon
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <advert-videos></advert-videos>
      </v-tab-item>

      <v-tab-item>
        <advert-flyers></advert-flyers>
      </v-tab-item>

      <v-tab-item>
        <advert-documents></advert-documents>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>

import { mdiVideoAccount, mdiFileImage, mdiFileDocument } from '@mdi/js'
import { ref } from '@vue/composition-api'

// demos
import AdvertVideos from './AdvertVideos.vue'
import AdvertFlyers from './AdvertFlyers.vue'
import AdvertDocuments from './AdvertDocuments.vue'

export default {
  components: {
    AdvertVideos,
    AdvertFlyers,
    AdvertDocuments,
  },
  setup() {
    const tab = ref('')

    // tabs
    const tabs = [
      { title: 'Videos', icon: mdiVideoAccount },
      { title: 'Flyers', icon: mdiFileImage },
      { title: 'PDFs', icon: mdiFileDocument },
    ]

    return {
      tab,
      tabs,
      icons: {
        mdiVideoAccount,
        mdiFileImage,
        mdiFileDocument,
      },
    }
  },
}
</script>
