var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{},[_c('span',{staticClass:"me-3"},[_vm._v("("+_vm._s(_vm.resourcesList.length)+") Advert Videos")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{staticClass:"text-xs font-weight-semibold mt-1",attrs:{"small":"","elevation":"10","color":"info darken-2","to":"/savings/marketing/add/resources/Video"}},[_c('font-awesome-icon',{staticClass:"pe-1",attrs:{"icon":"fa-solid fa-plus"}}),_vm._v(" Add New Video ")],1)],1),_c('v-data-table',{staticClass:"table-rounded",attrs:{"headers":_vm.headers,"items":_vm.resourcesList,"item-key":"id","items-per-page":10,"disable-sort":"","search":_vm.search},scopedSlots:_vm._u([{key:"item.resource_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column",staticStyle:{"cursor":"pointer"}},[_c('span',{staticClass:"d-block font-weight-semibold text--primary text-truncate"},[_vm._v(_vm._s(item.resource_name))]),_c('small',[_vm._v(_vm._s(_vm._f("truncate")(item.resource_description,50, '...')))])])]}},{key:"item.resource_size",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.resource_size)+" ")]}},{key:"item.get_file",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.get_file,"target":"_blank"}},[_c('v-btn',{staticClass:"primary",attrs:{"elevation":"6","icon":"","small":""}},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-download"}})],1)],1)]}},{key:"item.get_date_str",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.get_date_str)+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }